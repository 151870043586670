$mainBlue: #0771ed;
$tableHover: rgba(104, 166, 241, 0.25);

.action_button {
  &:disabled {
    background-color: #686869 !important;
    color: white;
  }

  background-color: $mainBlue;
  color: #ffffff;
  height: 32px;
  padding: 5px 12px;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 500;
  border-radius: 4px;


  &:hover {
    background: #0959b7;
    color: #ffffff;
  }

  bg: transparent;
  border-radius: 0.375rem;
  disabled-opacity: 0.65;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
}

.second_button {
  border: 1px solid $mainBlue;
  background-color: white;
  color: $mainBlue;
  height: 32px;
  &:disabled {
    background-color: #686869 !important;
    color: white;
  }
  &:hover {
    border: 1px solid #0867d7;
    color: white;
    background-color: #0867d7;
    background-image: none;
  }

  padding: 5px 12px;
  font-weight: 400;
  line-height: 1.5;
  bg: transparent;
  border-radius: 0.375rem;
  disabled-opacity: 0.65;
  focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
}

.red_button {
  border: 1px solid #d13a3a;
  background-color: white;
  color: #d13a3a;
  height: 32px;
  padding: 5px 12px;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 500;
  border-radius: 4px;

  &:hover {
    border: 1px solid #d13a3a;
    color: white;
    background-color: #d13a3a;
  }

  border-radius: 0.375rem;
  disabled-opacity: 0.65;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
}

.formio_redButton {
  button {
    border: 1px solid #d13a3a;
    background-color: white;
    color: #d13a3a;
    height: 32px;

    &:hover {
      border: 1px solid #d13a3a;
      color: white;
      background-color: #d13a3a;
    }

  }

}

.second_button_formio {
  button {
    border: 1px solid $mainBlue !important;
    background-color: white !important;
    color: $mainBlue !important;
    height: 33.5px;

    &:hover {
      color: white;
      border: 1px solid #0867d7;
      background-color: #0867d7;
      background-image: none;
    }

    &:disabled {
      border: 1px solid #898989;
      background-color: #898989;
    }

  }
}

app-dashboard {
  .ikbs-refresh:not(.action_button) {
    color: var(--main-blue);
    font-size: 28px;
    cursor: pointer;
    border-radius: 40px;
    background-color: #eff2fa;
    display: block;
    width: 32px;
    height: 32px;

    &:hover {
      color: #6A77A3;
    }

    &:before {
      margin-top: 2px;

      // border-radius: 16px;
      // background-color: #eff2fa;
      // width: 34px;
      // height: 32px;
    }
  }
}


[class^="ikbs-"]:before,
[class*=" ikbs-"]:before {
  // margin-left: 0;
  margin: 0;

}

.ikbs-withdraw:before {
  width: 22px;
}

.ikbs-deposit:before {
  width: 22px;
  font-size: 17px;
}

.ikbs-filter {
  font-size: 16px;
}

pre {
  font-family: "Roboto-regular";
  font-size: 13px;
}

.ikbs_shaker {
  &:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

}

.ikbs-vip,
.ikbs-user-alert-2 {
  font-size: 13px;

  &::before {
    margin-left: 0;
  }
}

.ikbs-binn,
.ikbs-vip-1 {
  font-size: 12px;
  margin-right: 4px;
}

.ikbs-plus:before {
  font-weight: 800;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #0867d7 !important;
  color: #ffffff;
  background-color: #0867d7 !important;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-state-focus {
  box-shadow: none !important;
}

.k-checkbox {
  background-color: #eff2fa;
  border: 1px solid #c6cddf;
  color: white;
  opacity: 1;
  width: 16px;
  height: 16px;
  pointer-events: all;
}

.mulLabel{
  font-size: 14px;
  line-height: normal;
}

.k-checkbox::before {
  width: 16px;
  height: 16px;
  font-size: 16px;
  // background-color: #0867d7;
  // border: 1px solid #0867d7;
}

.menu_label {
  color: #8692BA;
}

.content_container {
  width: 100%;
  padding-left: 0px;
  background: #eff2fa;
  flex: 1 1 auto;

}

main {
  position: relative;
}

.ikbs_tool {
  ik-messages {
    width: 100%;
    height: 100vh;
  }

  position: relative;
  height: 100%;
  filter: brightness(1) invert(1);
}

.dump_column_cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 100svh;
  position: absolute;
  z-index: 20000;
}

::selection {
  background: $mainBlue !important;
  color: white;
}

::-moz-selection {
  background: $mainBlue !important;
  color: white;
}

.nameMan,
.tokenMan,
.deployMan,
.cashMan {
  padding: 12px 20px;

  .k-dropdown {
    width: 100%;
  }
}

.back_top_cont {
  right: 54px;
  position: fixed;
  bottom: 56px;
  z-index: 50000;
  cursor: pointer;
  visibility: hidden;

  .back_top {
    width: 45px;
    height: 45px;
    background-color: transparent;
    border-radius: 35px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    font-weight: 100;
    color: #6A77A3;
    line-height: 1.5;
    background: rgba(255, 255, 255, 0.52);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  }

  &:hover {
    .back_top {
      background-color: rgb(70, 149, 243);
      color: white;

    }
  }
}

.token_input {
  border-radius: 3px;
  height: 28px;
  width: 100%;
}

.showMenu {
  width: 220px;
  animation-name: dumpMenu;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes dumpMenu {
  0% {
    width: 0px;
    color: white;
    padding: 0;
  }

  20% {
    width: 220px;
  }

  80% {
    width: 220px;
  }

  100% {
    color: #000;
    width: 220px;
    padding: 24px 4px 12px;
  }
}

.hot_menu_items {
  width: 100%;
  padding: 0 2px;
  margin: 0%;
  cursor: pointer;
}

.hot_menu_item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;

  a {
    color: #000;
  }

}

.hot_menu_item_selected {
  background: #c6cddf;
  border-radius: 4px;
}

.k-level-0 {
  width: 100%;
  padding: 10px 8px;
  margin-left: 10px;
  position: relative;

}

.closedMenu {
  margin-left: 8px;

}

.k-level-1 {
  width: 100%;
  padding: 13px 4px 13px 12px;
}

.k-level-2 {
  width: 100%;
  margin-left: 25px;
  margin-right: 12px;
  padding: 12px 4px 12px 12px;

  &:hover {
    background-color: $mainBlue;
    border-radius: 4px;

    a {
      color: white;
    }
  }
}

.k-level-3 {
  width: 100%;
  margin-left: 60px;
  margin-right: 12px;
  padding: 12px 4px 12px 12px;
}

.menuIcon {
  margin-right: 15px;

  &::before {
    font-size: 20px;
  }
}

.dumpBreadScrumpCont {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  opacity: 0.5;
  // margin-left: 32px;
}

.dumpBreadScrumpCont-item {
  margin: 2px;
}

.notification_menu {
  border-radius: 2px;
  background-color: #C93636;
  color: white;
  padding: 2px;
  margin-left: 10px;
  height: 20px;
  font-size: 11px;
}

.hotterLogo {
  width: 90px;
  height: 41px;
  background-image: url("../assets/Logo-1-1.svg");
  display: block;
  background-size: cover;
}

.ManagedhotterLogo {
  width: 90px;
  height: 41px;
  background-image: url("../assets/Logo-1-1.svg");
  display: block;
  background-size: cover;
  position: relative;
  left: 132px;
}

.k-drawer-items {
  margin-bottom: 40px;
}

// .projectAdd {
//   margin: 16px 0px 75px 10px !important;
// }
.autumn{
  .menuHeaderTemplate {
    background: url('../assets/seasons/03-38-03-793_512.gif');
  }
}

.magic{
  .menuHeaderTemplate {
    background: url('../assets/seasons/stars.gif');
  }
}

// .winter{
//   .menuHeaderTemplate {
//     background: url('../assets/seasons/snowing.gif');
//   }
// }

.menuHeaderTemplate {
  margin: 0px 0px 24px 0px;
  // height: 151px;


  .hotter_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 12px 0 12px;
  }

  .ikbs-frame-61760.sideBarMenuIcon {
    margin-top: 11px;
  }


  .sideBarMenuIcon {
    // position: absolute;
    // right: 13px;
    // top: 21px;
    cursor: pointer;
    z-index: 1;

    &::before {
      font-size: 31px;
    }
  }

  button {
    position: absolute;
    right: 15px;
    top: 19px;
  }


  h5 {
    margin-top: 16px;
  }

  .closeSideBarMenuIcon {
    right: 12px;
  }
}

.ManagedsideBarMenuIconCont {
  position: fixed;
  right: 0;
  top: 15px;
  z-index: 100000;
  width: 35px;
  height: 40px;
  background-image: linear-gradient(#2A3354, #1A2036, #262E4A, #273258);
  border-radius: 10px 0px 0px 10px;
}

.ManagedsideBarMenuIcon {
  &::before {
    font-size: 31px;
    color: white;
  }

  padding: 4px 3px;
}

.managedmenuHeaderTemplate {
  margin: 16px 0px 25px 10px;

  .ManagedsideBarMenuIconColapse {
    position: absolute;
    left: 15px;
    top: 25px;

    &::before {
      font-size: 31px;
    }

    cursor: pointer;
    z-index: 100000;
  }
}

.token {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.deploy {
  margin-top: 10px;
}

.refreshRotate {
  -webkit-animation: spinRefresh 2s linear infinite;
  -moz-animation: spinRefresh 2s linear infinite;
  -ms-animation: spinRefresh 2s linear infinite;
  -o-animation: spinRefresh 2s linear infinite;
  animation: spinRefresh 2s linear infinite;
}

@-webkit-keyframes spinRefresh {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinRefresh {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

ik-dropdown {
  min-width: 124px;
  max-width: 100%;
  width: 100%;
  position: relative;

  ul {
    max-width: 100%;
    width: 100%;
  }
}


.panel_dropdown {
  padding: 58px 12px 0px 10px;
  height: 110px;

  ik-dropdown {
    .dropdown {
      width: 215px;
      border-color: #404867;
      color: white;
      background-color: #202845;
      background-image: unset;
    }

    .item-list {
      width: 215px;
      color: white;
      background-color: #303a5f;
      border: 1px solid #202845;
      max-width: 215px;
    }
  }
}

.project_drop {
  padding: 6px 12px 0px 10px;
  height: 52px;

  ik-dropdown {
    .dropdown {
      width: 215px;
      border-color: #404867;
      color: white;
      background-color: #202845;
      background-image: unset;
    }

    .item-list {
      width: 215px;
      color: white;
      background-color: #303a5f;
      border: 1px solid #202845;
      max-width: 215px;

    }
  }
}

.emergencyExit {
  position: absolute;
  bottom: 15px;
}


/* KENDO GLOBAL SET */
.k-calendar .k-button-flat-primary {
  color: $mainBlue !important;
}
.k-button-flat-primary{
  color: black;

}

.k-table-md{
  font-size: 14px;
}

.k-calendar-td.k-state-selected:hover {
  color: #ffffff;
  background-color: $mainBlue !important;
}

.k-calendar .k-calendar-td.k-selected .k-link {
  background-color: $mainBlue !important;
}

.whole-cell {
  text-align: center;
  font-size: 27px;
  color: #d13a3a;
  //width: 30px;
}

.editor_ikbs {
  h1 {
    font-size: 36px;
    font-family: "Roboto-regular";
  }

  h2 {
    font-size: 24px;
    font-family: "Roboto-regular";
  }

  h3 {
    font-size: 18px;
    font-family: "Roboto-regular";
  }

  h4 {
    font-size: 14px;
    font-family: "Roboto-regular";
  }

  h5 {
    font-size: 12px;
    font-family: "Roboto-regular";
  }

  h6 {
    font-size: 10px;
    font-family: "Roboto-regular";
  }
}


.ed_Heading1 {
  font-size: 36px;
  font-family: "Roboto-regular";
}

.ed_Heading2 {
  font-size: 24px;
  font-family: "Roboto-regular";
}

.ed_Heading3 {
  font-size: 18px;
  font-family: "Roboto-regular";
}

.ed_Heading4 {
  font-size: 14px;
  font-family: "Roboto-regular";
}

.ed_Heading5 {
  font-size: 12px;
  font-family: "Roboto-regular";
}

.ed_Heading6 {
  font-size: 10px;
  font-family: "Roboto-regular";
}

.ed_Heading_ULTRA {
  font-size: 48px;
  animation: blink 1s infinite;
  display: inline-block;
}

.k-grid-header .k-sort-order {
  color: black;
}

.k-dialog-titlebar-actions {
  background: #E5E8F1;
  border-radius: 4px;

  &:hover {
    background-color: #007bff;

    kendo-icon {
      color: white !important;
    }

    opacity: 1;
  }
}

.k-editor {
  .k-dialog-actions {
    background: none;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    &:hover {
      background: none;
      color: white;
      opacity: 1;
    }

    .k-button:not(.k-button-solid-primary) {
      @extend .second_button;
    }

    .k-button-solid-primary {
      border-color: #E5E8F1;
      @extend .action_button;

    }
  }
}


.k-calendar .k-state-selected .k-link {
  color: #ffffff;
  background-color: $mainBlue !important;
  border-color: $mainBlue !important;
}

// .k-calendar .k-scrollable-placeholder{
//   width: 0px;
// }
.k-calendar .k-content.k-scrollable {
  padding-right: 255px;
  padding-left: 100px;
  margin-left: -100px;
  margin-right: -270px;
}

kendo-virtualization {
  td:hover {
    background-color: #cfd5e2;
  }

  .k-weekend:hover {
    background-color: #cfd5e2;
  }
}

.k-toolbar {
  overflow: visible;
}

// .k-calendar .k-content .k-weekend{
//   font-weight: 700;
// }

.k-calendar .k-content .k-state-disabled {
  opacity: 0.6;
  font-weight: 400;
}

.k-calendar .k-calendar-view .k-today {
  color: $mainBlue !important;
}

.k-grid .k-detail-row {
  background: #eff2fa;
}

.k-grid .k-table-tbody>.k-table-row:not(.k-detail-row):hover {
  background-color: $tableHover;
}

.k-grid .k-grid-content-locked .k-selected:hover .k-table-td {
  background-color: $tableHover;
}


.k-grid .k-grid-content-locked .k-selected td {
  background-color: $tableHover;
}

.k-grid .k-table-row.k-selected>td {
  background-color: $tableHover;
}

.k-grid .k-table-row {
  background: white;
}

.k-grid .k-table-row.k-table-alt-row {
  background-color: rgb(7 113 237 / 4%);
}

.k-grid .k-grid-content-locked .k-selected.k-table-alt-row .k-table-td {
  background-color: $tableHover;
}

.k-grid tr.k-state-selected>td {
  background-color: $tableHover;
}

//.k-grid tr.k-state-selected {
//  background-color: rgba(104, 166, 241, 0.2);
//}

.k-grid .k-table-row.k-selected>.k-table-td {
  background-color: rgba(104, 166, 241, 0.2);
}



.k-grid .k-hierarchy-cell+td {
  border-left-width: 1px;
}

.k-hierarchy-cell {
  .k-minus {
    font-size: 18px;
    transform: rotate(-90deg);
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
  }

  .k-plus {
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;

  }

  .k-i-plus {
    width: 10px;
    font-size: 24px;

  }

  .k-i-minus {
    width: 10px;
    font-size: 24px;

  }

  .k-i-minus::before {
    font-family: ikbs;
    content: "\E801";
  }

  .k-i-plus::before {
    font-family: ikbs;
    content: "\E809";
  }
}

.k-editor-dialog {
  .k-checkbox-label {
    margin-left: 25px;

    &::before {
      position: absolute;
      left: -25px;
    }

    &::after {
      position: absolute;
      left: -25px;
    }
  }
}

kendo-dialog,
kendo-window {
  .k-dialog {
    border-radius: 8px;
  }

  .k-dialog-titlebar {
    background: #f9fafd;
    padding: 8px 8px 8px 16px;
    color: #000;
    border-radius: 8px 8px 0px 0px;
  }
}


.k-primary {
  border-color: $mainBlue !important;
  background-color: $mainBlue !important;
  color: #ffffff !important;
  background: $mainBlue !important;
  background-image: none !important;
}

.k-primary :hover {
  background-color: #0867d7 !important;
  color: #ffffff !important;
  background: #0867d7 !important;
  background-image: none !important;
}

.k-textbox,
.k-numeric-wrap,
.k-dateinput-wrap {
  width: 100%;
  color: #202845;
  background-color: white;
  border-radius: 5px;
  line-height: 1.6;
  //padding: 5px 0px 0px 10px;
  border: 1px solid #c6cddf;
  font-family: "Roboto-regular";
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.k-dateinput-wrap {
  padding: 0;
  border-radius: 5px !important;
  color: #202845 !important;
  background-color: white !important;

}

kendo-datepicker {
  .k-button-solid-base {
    border-color: #c6cddf;
    color: #202845;
    background-color: white;
    background-image: unset;
    // border-radius: 5px;
  }
}

.k-select {}

.k-picker-wrap {
  border: 1px solid #c6cddf !important;

  //   border:1px solid #c6cddf;
  .k-select {
    color: #202845;
    background-color: white;
    background-image: unset;
    border-radius: 5px;
  }

  color: #202845;
  background-color: white;
  background-image: unset;
  border-radius: 5px;
}

.k-numeric-wrap {
  padding: 0;
}

.k-i-loading {
  position: absolute;
  top: 50%;
  left: 50%;

  font-size: 64px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #0867d7;
  transform: translate(-50%, -50%);
}

.rowHint {
  background-color: #ededed;
  padding: 5px;
  margin: 5px;
  pointer-events: none;
}

.rowHint>td {
  border: none;
}

.k-drag-clue {
  background-color: yellow;
}

.k-datepicker,
.k-picker-wrap {
  color: #000;
}

.kendo-grid-columnmenu-filter {
  font-family: "Roboto-regular" !important;
}

.k-filter-menu-container kendo-grid-filter-menu-input-wrapper,
.k-filter-menu-container kendo-grid-filter-menu-input-wrapper,
kendo-grid-string-filter-menu {
  font-family: "Roboto-regular";
}

.k-grid-columnmenu-popup,
.k-grid-filter-popup {

  // max-width: 220px;
  // max-height: 300px;
  .range1cont,
  .range2cont {
    ik-dropdown {
      .dropdown {
        width: 100%;
      }

      .item-list {
        width: 100%;
      }
    }
  }

  .orAndCont {
    ik-dropdown {
      .dropdown {
        width: 80px;
        min-width: 80px;
      }

      .item-list {
        width: 80px;
        min-width: 80px;
      }
    }
  }

  .k-textbox {
    padding: 5px 4px;
  }

  .k-columnmenu-item {
    padding: 12px 70px 0px 17px;
    font-size: 16px;
    font-weight: 700;
    font-family: "Roboto-regular";

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }

    .k-icon {
      display: none;
    }
  }

  .k-columnmenu-item-wrapper {
    height: 100%;
  }

  .k-columnmenu-item-content {
    overflow: visible !important;
    .k-widget {
      margin: 4px 0;
    }
  }

  kendo-dropdownlist {
    border: 1px solid #c6cddf;
    border-radius: 5px;
    background: none;

    .k-dropdown-wrap {
      color: #202845;
      background-color: white;
      background-image: unset;
      border-radius: 5px;
    }
  }

  .k-columnmenu-actions,
  .k-actions {
    padding: 0 !important;
    flex-direction: row-reverse;

    button {
      border-radius: 4px !important;
      padding: 4px 8px;
      height: 32px;
      font-size: 14px;
    }

    button:not(:disabled) {
      border: 1px solid $mainBlue;
      background-color: white;
      color: $mainBlue;
    }

    .k-button-solid-primary:not(:disabled) {
      background: $mainBlue;
      border: 1px solid $mainBlue;
      color: white;
    }

    .k-button-solid-primary {
      background: $mainBlue;
      border: 1px solid $mainBlue;
      color: white;
      height: 32px;
      font-size: 14px;
    }
  }
}

.k-picker-solid {
  background: white;
}

.k-animation-container {
  .k-popup {
    background-color: white !important;
    color: black;
  }

  .k-state-hover {
    // background-color: #202845 !important;
  }

  .k-state-selected {
    // background-color: $mainBlue !important;
  }

  // .k-item:hover {
  //   background-color: #202845 !important;
  // }
  .k-selected.k-list-optionlabel {
    display: none;
  }


  .k-list-item.k-selected {
    background-color: $mainBlue !important;
    color: white;
  }

  .k-list-item:hover {
    background-color: $mainBlue !important;
    color: white;
  }

  .k-list-item{
    word-break: keep-all;
    word-wrap: nowrap;
    white-space:nowrap;
  }

  .k-list-content::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .k-list-content {
    // scrollbar-width: thin;
  }

  .k-list-content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .k-list-content::-webkit-scrollbar-thumb {
    background: #dbe0eb;
    border-radius: 2px;
  }

  /* Handle on hover */
  .k-list-content::-webkit-scrollbar-thumb:hover {
    background: #2a3354;
  }
}

kendo-drawer-content {
  height: 100vh;

  @media screen and (max-width: 768px) {
    height: 100svh;

  }

  @media screen and (max-height: 460px) {
    height: 100svh;

  }

  @media screen and (max-width: 640px) {
    padding-left: 55px;

  }

  overflow: auto;
  // height: 100%;
  background: #eff2fa;
  // padding-left: 10px;
  display: flex;

  &::-webkit-scrollbar {
    width: 12px;
    height: 10px;
  }

  & {
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #8692ba;
    border-radius: 2px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #2a3354;
  }


}

.k-drawer-push .k-drawer {
  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 10150;
  }

}
.dev{
  .k-drawer {
    background-image: linear-gradient(#273258,#2d3f83,#b8245a, #ea3a7a);
  }
}
.magic{
  .k-drawer {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: url('../assets/seasons/magic.jpg');
      background-size: cover;
      opacity: .3;
      content: "";
      z-index: 0;
    }
  }
}

.autumn{
  .k-drawer {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: url('../assets/seasons/20-17-49-581_512.webp');
      background-size: cover;
      opacity: .3;
      content: "";
      z-index: 0;
    }
  }
}

.winter2{
  .k-drawer {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: url('../assets/seasons/snowing.gif');
      background-size: cover;
      opacity: .8;
      content: "";
      z-index: 0;
    }
  }
}

.winter{
  .k-drawer {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: url('../assets/seasons/winter5.png');
      background-size: cover;
      opacity: .8;
      content: "";
      z-index: 0;
    }
  }
}

.stage{
  .k-drawer {
    background-image: linear-gradient(#273258,#2d3f83,#b87124, #8c561c)
  }
}
.demo{
  .k-drawer {
    background-image: linear-gradient(#273258,#2d3f83,#24b83d, #092d11)
  }
}
.k-drawer {
  background-image: linear-gradient(#2A3354, #1A2036, #262E4A, #273258);
  color: white;
  height: 100vh !important;
 

  @media screen and (max-width: 768px) {
    height: 100svh !important;

  }

  .k-drawer-wrapper {
    min-height: 500px;
z-index: 1;
    /* OLD version */
    &::-webkit-scrollbar {
      max-width: 12px;
      max-height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #8692ba;
      border-radius: 2px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #2a3354;
    }

    /* NEW version */
    scrollbar-color:#8692ba #f1f1f1;

    scrollbar-color:hover {
      background: #2a3354;
    }
  }

  // background-image: url("../assets/sidebarbg.jpg");
}

.k-drawer-item {
  margin-left: 0;
  padding: 0;
  outline: 0;
  color: inherit;
  font-size: 14px;
  line-height: 1.6;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;

  // background-color:white;
  a {
    text-decoration: none;
    color: white;
    display: flex;

  }
}

kendo-drawer-container {
  flex: 1 1 auto;
  overflow-y: auto;
  position: fixed;
  width: 100%;

}

.k-drawer-item.k-state-selected {
  background-color: #202845;

  &:hover {
    background-color: $mainBlue;
  }
}

.k-drawer-item:hover,
.k-drawer-item .k-state-hover {
  color: white;
  background-color: $mainBlue;
}

.k-drawer-item:focus,
.k-drawer-item.k-state-focused {
  color: white !important;
  background-color: $mainBlue !important;
}

.k-drawer-item.k-selected {
  color: white !important;
  background-color: $mainBlue !important;
}

.form_Administration {
  .k-popup {
    background-color: white !important;
    color: black;
  }

  .k-state-hover {
    background-color: #eff2fa !important;
  }

  .k-state-selected {
    background-color: #eff2fa !important;
    color: black !important;
  }

  .k-item:hover {
    background-color: #eff2fa !important;
  }

  .k-item:selected {
    background-color: #eff2fa !important;
    color: black !important;
  }
}

thead {
  .k-link {
    font-family: "Roboto-regular";
    font-size: 12px;
    line-height: 1.6;
    font-weight: 500;
    color: #7f848f;
    text-transform: uppercase;
  }

  .k-header {
    font-family: "Roboto-regular";
    font-size: 12px;
    line-height: 1.6;
    font-weight: 500;
    color: #7f848f;
    text-transform: uppercase;
  }

  th:hover {
    background-color: #eff2fa;
  }
}

tbody {
  tr.k-alt {
    //background-color: #f9fafd !important;
  }

  td {
    p {
      margin: 0px;
    }
  }
}

.k-grid {
  border-color: white;
  color: black;
  background-color: #ffffff;
}

.k-grid-header {
  padding: 0 8px 0px 0px !important;
  color: black;
  border-color: transparent;
  background-color: white;
}


.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
  color: black !important;
  //background-color: unset !important;
  border-color: white;
  padding: 0px 8px 0px 0px !important;
}

.k-grid-toolbar {
  color: black !important;
  background-color: unset !important;
  border-color: white;
}

.k-grid .k-grid-header .k-table-th,
.k-grid-md .k-filter-row td {
  background-color: white;

}
.k-grid-md .k-grid-header .k-grid-header-menu{
  padding: 0px;
  margin: 0;
  width: calc(1.2em + 10px);;

}
.k-grid .k-grid-header .k-table-th {
  .k-grid-header-menu.k-active {
    background-color: rgba(7, 113, 237, 0.2);
    color: black;
    border-radius: 20px;
  }
}

.k-grid td,
.k-grid .k-table-td::selection {
  //background-color: rgba(7, 113, 237, 0.07);
}

.k-grid-content-locked {
  border-color: rgba(0, 0, 0, 0.08) !important;

  td {
    border-color: rgba(0, 0, 0, 0.08) !important;
  }
}

.k-grid-header-locked {
  border-color: rgba(0, 0, 0, 0.08) !important;

  td {
    border-color: rgba(0, 0, 0, 0.08) !important;
  }
}

.k-grid-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.k-grid-content {
  // scrollbar-width: thin;
}

/* Track */
.k-grid-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.k-grid-content::-webkit-scrollbar-thumb {
  background: #dbe0eb;
  border-radius: 2px;
}

/* Handle on hover */
.k-grid-content::-webkit-scrollbar-thumb:hover {
  background: #2a3354;
}

kendo-window {
  .k-window-content {
    overflow: scroll;
    padding-block: 16px;
        padding-inline: 16px;
  }
}

kendo-dialog {
  .k-window-content {
    padding-block: 16px;
        padding-inline: 16px;
  }
}

kendo-grid-toolbar {
  display: flex !important;
  height: 56px;
  align-items: center;
  color: black !important;
  background-color: white !important;

  h1:first-letter {
    margin-left: 12px;
    text-transform: capitalize;
  }

  kendo-grid-column-chooser {
    display: flex;
    align-items: center;
    flex: 0.2;

    .editTable {
      margin: 0px;
      margin-right: 24px;
    }
  }

  kendo-grid-spacer {
    flex: 1 0 auto;
  }
}

kendo-grid-group-panel {
  padding: 0px 16px;
  margin-bottom: 8px;

  .k-indicator-container {
    text-align: center;
    font-family: "Roboto-regular";
    font-size: 14px;
    line-height: 1.6;
    font-weight: bold;
    color: $mainBlue;
    background-color: rgba(7, 113, 237, 0.1) !important;
    border: 1px dashed $mainBlue;
  }
}

kendo-grid-filter-menu {
  .k-state-active {
    color: #ffffff;
    background-color: transparent !important;

    .k-i-filter:before {
      content: "";
    }

    .k-i-filter {
      background: url("../assets/filter_default_filtered.png");
      background-position: center;
      width: 25px;
      height: 25px;
    }
  }

  .k-grid-filter {
    color: #ffffff;
    background: url("../assets/filter_default.png");
    background-size: cover;
    background-color: transparent !important;
    bottom: 10px;

    .k-i-more-vertical:before {
      content: "";
    }

    &:hover {
      background-color: #dbe0eb !important;
      border-radius: 15px;
    }
  }

  .k-i-filter:before {
    content: "";
  }
}

kendo-grid-column-menu {
  .k-state-active {
    color: #ffffff;
    background: url("../assets/filter_default_filtered.png") !important;
    background-size: cover;

    .k-i-more-vertical:before {
      content: "";
    }

    width: 24px !important;
    height: 24px !important;
  }

  .k-grid-filter {
    color: #ffffff;
    background: url("../assets/filter_default.png");
    background-size: cover;
    background-color: transparent !important;
    bottom: 10px;

    .k-i-more-vertical:before {
      content: "";
    }

    &:hover {
      background-color: #dbe0eb !important;
      border-radius: 15px;
    }
  }
}

.k-pager {
  overflow-x: auto;
  scrollbar-width: thin;
  &::-webkit-scrollbar-thumb {
    background: #dbe0eb;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
}

kendo-pager {
  .k-pager-numbers .k-selected {
    color: white !important;
    background-color: $mainBlue !important;
    border-radius: 4px;
  }

  .k-pager-numbers {
    .k-pager-nav:hover {
      border-radius: 4px;
      background-color: rgba(7, 113, 237, 0.4) !important;
    }
  }

  .k-i-caret-alt-to-left {
    font-family: ikbs;

    &:before {
      content: "\E804";
    }
  }

  .k-i-caret-alt-left {
    font-family: ikbs;

    &:before {
      content: "\E82F";
    }
  }

  .k-i-caret-alt-right {
    font-family: ikbs;

    &:before {
      content: "\E83F";
    }
  }

  .k-i-caret-alt-to-right {
    font-family: ikbs;

    &:before {
      content: "\E824";
    }
  }

  //
  //
  //kendo-pager-prev-buttons,
  //kendo-pager-next-buttons {
  //  .k-icon {
  //    font-size: 20px;
  //  }
  //}
  //
  //kendo-pager-prev-buttons {
  //  .k-i-seek-w {
  //    font-family: "ikbs";
  //
  //    &::before {
  //      content: "\e804";
  //    }
  //  }
  //
  //  .k-i-arrow-w {
  //    font-family: "ikbs";
  //
  //    &::before {
  //      content: "\e82f";
  //    }
  //  }
  //}
  //
  //kendo-pager-next-buttons {
  //  .k-i-seek-e {
  //    font-family: "ikbs";
  //
  //    &::before {
  //      content: "\e824";
  //    }
  //  }
  //
  //  .k-i-arrow-e {
  //    font-family: "ikbs";
  //
  //    &::before {
  //      content: "\e83f";
  //    }
  //  }
  //}
  //
  //.k-pager-numbers-wrap {
  //  kendo-pager-numeric-buttons {
  //    .k-pager-numbers {
  //      .k-link:link {
  //        font-family: "Roboto-regular";
  //        font-size: 14px;
  //        line-height: 1.6;
  //        color: #1b1d21;
  //      }
  //    }
  //
  //    .k-selected {
  //      color: white !important;
  //      background-color: $mainBlue !important;
  //      border-radius: 4px;
  //    }
  //
  //  }
  //
  //  .k-pager-nav {
  //    &:hover {
  //      background-color: var(--main-Hover) !important;
  //    }
  //  }
  //}
  //
  //
  //kendo-pager-page-sizes {
  //  select {
  //    border-radius: 4px;
  //    height: 32px;
  //    border: 1px solid #c6cddf;
  //    padding-left: 5px;
  //    width: 72px;
  //  }
  //
  //  font-family: "Roboto-regular";
  //  font-size: 14px;
  //  line-height: 1.6;
  //  color: #2a3354;
  //}
  //
  //kendo-pager-info {
  //  font-family: "Roboto-regular";
  //  font-size: 14px;
  //  line-height: 1.6;
  //  color: #2a3354;
  //}
}

kendo-popup {
  .k-popup {}
}

kendo-grid-filter-cell-operators {
  .k-dropdown .k-dropdown-wrap {
    background: transparent !important;

    .k-select {
      width: 30px;
      height: 30px;
    }
  }

  .k-clear-button-visible {
    width: 30px;
    height: 30px;
  }
}


kendo-list {
  .k-list-scroller {
    .k-list {
      .k-item {
        &:hover {
          background-color: #F9FAFD;
          color: #0771ED;
        }

        &:focus {
          background-color: #F9FAFD;
          color: #0771ED;
        }

      }

      .k-state-selected {
        background-color: #0771ED;
        color: white;
      }
    }
  }

}

//FORMIO
.fileSelector {
  display: block !important;
}
formio{
  .formio-loader {
    min-height: 60px;
  }
  // .radio  input[type=radio]{
  //   margin-left: 10px;
  // }
  
}


formio .formio-component-datagrid .datagrid-table td,
//formio .formio-component-datagrid,
formio .formio-component-datagrid .datagrid-table,
formio .formio-component-datagrid .datagrid-table th {
  border: 1px solid #ddd !important;
  overflow: visible;
}

.formio-component-submit {
  .btn-success {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
    }

    &:focus {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }
  }

  .submit-success {
    i {
      display: none;
    }

  }

  .btn-danger {
    color: #fff;
    background-color: #E95B5B;
    border-color: #E95B5B;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      color: #fff;
      background-color: #D13A3A;
      border-color: #FA9494;
    }

    &:focus {
      color: #fff;
      background-color: #D13A3A;
      border-color: rgb(250, 148, 148);
      box-shadow: 0 0 0 0.2rem rgb(250, 148, 148, 0.5);
    }

    i {
      display: none;
    }
  }

  .btn-primary {
    i {
      display: none;
    }
  }

}

.formioSubmitLoad {
  background: #68A6F1;
  color: #68A6F1;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  position: relative;

  &:focus {
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    outline: 0;
  }

}


.form-control {
  .choices__list--multiple {
    .choices__item {
      background-color: #4695F3;
      border: 1px solid #4695F3;
      border-radius: 4px;
      padding: 3px 6px;

    }

    .choices__item.is-highlighted {
      background-color: #0771ed;
      border: 1px solid #0771ed;
    }
  }
}

.choices__list--single {
  .game_dropdown {
    .provider {
      font-size: 11px;
      color: #8692BA;
    }

    .system {
      font-size: 11px;
      color: #6A77A3;
      float: right;
      margin-right: 10px;
      line-height: 21px;
    }
  }
}

button {
  .game_dropdown {
    background-color: #4695F3;
    border: 1px solid #4695F3;
    border-radius: 4px;
    padding: 3px 6px;
    color: white;
    display: flex;
    align-items: baseline;
    font-size: 12px;
    gap: 5px;
    line-height: 1em;

    .provider {
      font-size: 11px;
    }

    .system {
      font-size: 11px;
      float: right;
      margin-right: 10px;
      line-height: 16px;
    }
  }
}

.choices__list--dropdown .choices__item--selectable,
.choices__list[aria-expanded] .choices__item--selectable {
  padding-right: 50px !important;

}

.choices__list--dropdown {

  .game_dropdown {
    .provider {
      font-size: 11px;
      color: #8692BA;
    }

    .system {
      font-size: 11px;
      color: #6A77A3;
      float: right;
      margin-right: 10px;
      line-height: 21px;
    }
  }
}

.choices__list--multiple {
  .game_dropdown {
    display: contents;

    .provider {
      font-size: 11px;
    }

    .system {
      font-size: 11px;
      margin-right: 10px;
      line-height: 21px;
      text-decoration-line: underline;
    }
  }
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}


// .formio-component-submit {
//   .fa-refresh {
//     &::before {
//       content: "";
//     }
//   }
// }

.formio-button-remove-row {
  background: white;
  color: #E95B5B;
  font-size: 16px;
  border: none;

  &:hover {
    background: #68A6F1;
  }

  &::before {
    content: '\e83d';
    font-family: "ikbs";
    font-style: normal;
    font-weight: normal;
  }

  i {
    display: none;
  }
}
.fa-remove-circle {
  font-size: 16px;
  border: none;
  background-color: transparent;
  &:hover {
    background: #68A6F1;
  }

  &::before {
    content: "\e80c";
    font-family: "ikbs";
    font-style: normal;
    font-weight: normal;
  }

  i {
    display: none;
  }
}

.formio-form {
  label {
    color: #8692ba;
    font-family: "Roboto-regular";
    font-size: 10px;
    line-height: 2.1;
    font-weight: 700;
    text-transform: uppercase;
    //padding: 0;
  }
}

.tippy-box {
  max-width: 265px !important;
  font-size: 13px;
}

.tippy-content {
  text-transform: lowercase;
}

.formAdminCont {
  display: flex;
  flex-direction: row;

  .data {
    flex: 50%;
    min-width: 700px;
    position: relative;
    // overflow-x: auto;
  }
}

.formio-float-right {
  min-height: 32px;
  margin: 0 !important;

  button {
    float: right;
  }
}

.formioGreyOut {
  .selection {
    background-color: #e9ecef;

  }
}

.Formioswitcher {
  display: flex;
  align-items: center;
  gap: 6px;

  .form-check {
    .form-check-label {
      margin-left: 12px !important;
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    left: 18px;
  }

  input:before {
    content: '';
    position: absolute;
    width: 23px;
    height: 14px;
    border-radius: 12px;
    background-color: #ccc;
  }

  input:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    top: 1px;
    cursor: pointer;
    -webkit-transition: .2s;
    transition: .2s;
  }

  input:checked:after {
    transform: translateX(11px);
  }

  input:checked:before {
    background-color: #007bff;
  }


  input:checked+.slider {
    background-color: #007bff;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #007bff;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(11px);
    -ms-transform: translateX(11px);
    transform: translateX(11px);
  }

}

.formio-component-SliderComponent {
  display: flex;
  gap: 4px
}

/* The switch - the box around the slider */
.k-switch-on .k-switch-track {
  border-color: #0771ed;
  background-color: #0771ed;

}

.k-switch-on:focus .k-switch-track {
  border-color: #0771ed;
  outline: #0771ed;
}

.ikbs-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

/* Hide default HTML checkbox */
.ikbs-switch .ikbs-sw-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.ikbs-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.ikbs-slider:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.ikbs-sw-input:checked+.ikbs-slider {
  background-color: #2196F3;
}

.ikbs-sw-input:focus+.ikbs-slider {
  box-shadow: 0 0 1px #2196F3;
}

.ikbs-sw-input:checked+.ikbs-slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.ikbs-slider.round {
  border-radius: 34px;
}

.ikbs-slider.round:before {
  border-radius: 50%;
}

.ikbs-slider-text {
  position: relative;
  //bottom: 3px;
}





.ikbs_ {
  // $mainBlue:#76B245;
  // $tableHover:rgba(187, 232, 151, 0.25);
  // --main-blue: #76B245;
  --main-Hover: rgba(187, 232, 151, 0.25);

  .hotterLogo {
    width: 90px;
    height: 43px;
    background-image: url("../assets/premium-logo.png");
    display: block;
    background-size: cover;
  }

  .ManagedhotterLogo {
    width: 90px;
    height: 43px;
    background-image: url("../assets/premium-logo.png");
    display: block;
    background-size: cover;
    position: relative;
    left: 132px;
  }


  .k-drawer {
    background-color: #1da09230;
    // background-color:rgba(33, 157, 149);
    background-blend-mode: luminosity;
    background-image: url("../assets/sidebar_green6.jpg");



  }

  .k-drawer-item.k-state-selected {
    background-color: rgba(33, 157, 149, 0.25);

    &:hover {
      background-color: #116c62d9;
    }
  }

  .k-drawer-item:hover,
  .k-drawer-item .k-state-hover {
    color: white;
    background-color: #116c62d9;
  }

  .k-drawer-item:focus,
  .k-drawer-item.k-state-focused {
    color: white !important;
    background-color: #116c62d9 !important;
  }

  .k-level-2 {
    &:hover {
      background-color: #116c62d9;
      ;

    }
  }

}

//FONT AWESOME

.fa,
.fas {
  font-family: "ikbs";
  font-style: normal;
}

.fa-times-circle-o {

  &:before {
    content: "\e80c";
  }
}

.fa-remove {
  &:before {
    content: "\e80c";
  }
}

.fa-copy {
  &:before {
    content: "\E850";
  }
}

.fa-download {
  &:before {
    content: "\E837";
  }
}

.fa-wrench {
  &:before {
    content: "\E84A";
  }
}

.fa-arrows {
  &:before {
    content: "\E829";
  }
}

.fa-cog {
  &:before {
    content: "\E854";
  }
}

.fa-calendar {
  &:before {
    content: "\E814";
  }
}

.fa-plus {
  color: white !important;

  &:before {
    content: "\E827";
  }
}

.fas.fa-cog {
  font-size: 15px;

  &:before {
    content: "\E854";
  }
}

.fa-bars,.formio-drag-button {
  font-size: 15px;
  font-family: ikbs;

  &:before {
    content: "\E829";
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  // height: calc(1.5em + 0.75rem + 2px);
  height: 32px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  @media screen and (max-width: 640px) {
    font-size: 16px !important;
  }
}

.form-control {}

.choices {
  .form-control {
    height: 32px;
  }
}

.gu-mirror {
  // position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0;
  // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  // filter: alpha(opacity=80);
}


.formio-component .formio-component-datagrid {
  overflow: visible;

}

.formio-formio-form-group {
  margin-bottom: 1rem;

  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }

  input[type=checkbox],
  input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    width: 13px;
    height: 13px;
  }

  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }

  .input-group-text {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
  height: auto;
}


//TINE MCE
.tox-editor-container,
.defaultSkin img.mceIcon {
  display: block;
  width: 40px;
  height: 40px
}

.tox .tox-tbtn {
  //border:1px solid #F0F0EE !important;
  //width: 20px !important;
  //height:20px !important;
}

.tox-statusbar {
  display: none !important;
}