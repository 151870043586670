import { Injectable, inject, NgZone } from "@angular/core";
import { Subscription, interval } from "rxjs";
import { AuthService } from "./auth.service";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";

//[{url:"",req:"time",res:"time",UserAgent:"",status:code,RemoteAddress:"",email:"",size:""}]
@Injectable({
    providedIn: "root",
})
export class DcService {
    private timeInteval: Subscription | undefined
    AuthService: AuthService = inject(AuthService)
    http: HttpClient = inject(HttpClient);
    private userAgent = navigator.userAgent;
    ApiService: ApiService = inject(ApiService)


    public datatosend: any = [];

    //https://dc.shmalex.net/

    constructor() {
        if (!this.timeInteval) {
            inject(NgZone).runOutsideAngular(() => {
                this.timeInteval = interval(60 * 1000)
                    .subscribe(data => {
                        if (this.AuthService.isAuthenticated()) {
                            if (this.datatosend.length) {
                                const email = this.AuthService?.email;
                                const data = {
                                    data: {
                                        "dict": {
                                            "UA": this.userAgent,
                                            "email": email
                                        },
                                        "fields": ["url", "status", "size", "reqStart", "resDuration", "renderDuration"],
                                        "data": this.datatosend
                                    }
                                }
                                this.ApiService.Postmethod("client_stats", data).subscribe((response: any) => {
                                    this.datatosend.length = 0
                                })
                            }
                        }
                    })
            })
        }
    }
}

