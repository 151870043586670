<div
  class="global_container"
  [ngClass]="{ikbs_:panel_type}"
  [ngClass]="admin_env"
  [ngClass]="season_env"
>
  <!-- ikbs_tool: Panel_switcher.switch_state =='IKBS_TOOL', -->
  <!-- <ng-container *ngIf="log()"></ng-container> -->

  <!-- <main [@routeAnimations]='prepareRoute(outlet)'>

</main> -->
  <kendo-drawer-container>
    <kendo-drawer
      #drawer
      [items]="_Menu()"
      [mini]="true"
      [mode]="expandMode"
      [miniWidth]="55"
      [width]="246"
      [expanded]="expanded"
      (expandedChange)="leftToolBarExpandChanges($event)"
      (select)="selectMenu($event, true)"
      [autoCollapse]="false"
    >
      <ng-template kendoDrawerHeaderTemplate>

        <div
          class="menuHeaderTemplate"
          [ngClass]="{
              projectAdd: _currentPanel().applied_projects.length > 0 && expanded
            }"
        >
          @if(AuthService.isAuthenticated()){

          <div class="hotter_cont">
            @if (expanded) {
            <div class="hotterLogo hotter"></div>
            }
            @if (expanded) {
            <div
              (click)="leftSideBarOpenClose()"
              class="ikbs-frame-61761 sideBarMenuIcon"
            ></div>
            }
            @if (!expanded) {
            <div
              (click)="leftSideBarOpenClose()"
              class="ikbs-frame-61760 sideBarMenuIcon"
            ></div>
            }
          </div>
          <div class="panel_dropdown">
            @if (expanded) {
            <label class="menu_label sub_title3">CHOOSE PANEL</label>
            }
            @if (expanded) {
            <ik-dropdown
              [data]="_panelDropDownSignal()"
              [selectedProject]="_currentPanel()"
              (valueChange)="DashboardSelect($event)"
              (selectedProjectOut)="currentPanelChange($event)"
            >
            </ik-dropdown>
            }
          </div>
          }
          @if (_currentPanel().applied_projects.length) {
          <div class="project_drop">
            @if (expanded && _currentPanel().applied_projects.length) {
            <label class="menu_label sub_title3">CHOOSE PROJECT</label>
            }
            @if (expanded && _currentPanel().applied_projects.length) {
            <ik-dropdown
              [data]="_projectlist()"
              [selectedProject]="_selectedProject()"
              (valueChange)="projectselect($event)"
            >
            </ik-dropdown>
            }
          </div>
          }
        </div>
        @if (!AuthService.isAuthenticated()) {
        <a
          class="k-level-0 title6"
          [ngClass]="{closedMenu:!expanded}"
          style="display: flex;color:white;cursor:pointer"
          (click)="logout()"
        >
          <div
            class="menuIcon {{ logoutButton.icon }}"
            [title]="logoutButton.title"
          ></div>
          @if (expanded) {
          <span>{{ logoutButton.title }} </span>
          }
        </a>
        }
      </ng-template>
      @if(AuthService.isAuthenticated()){
      <ng-template
        kendoDrawerItemTemplate
        let-item
        let-index
      >
        @if (item.path) {
        <a
          class="k-level-{{ item.level }} title6"
          [ngClass]="{closedMenu:!expanded}"
          style="display: flex"
          [routerLink]="item.path"
        >
          <ng-container *ngTemplateOutlet="defaultLinks"></ng-container>
        </a>
        }
        @if (!item.path) {
        <div
          class="k-level-{{ item.level }} title6"
          [ngClass]="{closedMenu:!expanded}"
          style="display: flex"
        >
          <ng-container *ngTemplateOutlet="defaultLinks"></ng-container>
        </div>
        }

        <ng-template #defaultLinks>
          <div
            class="menuIcon {{ item.icon }}"
            [title]="item.title"
          ></div>
          @if (expanded) {
          <div [id]="'ikbs' + item.title">
            {{ item.title }}
            @if (currentLevelTwoItem === item.title && breadScrumps) {
            <div class="dumpBreadScrumpCont dumpBreadScrumpCont-item">
              {{ breadScrumps }}
            </div>
            }
          </div>
          }
          @if (!item.path && item?.children?.length > 0) {
          <div
            class="ikbs-arrow_right"
            style="position: absolute; right: 30px"
          ></div>
          }
          @if (item._alias == 'notifications' && SharedData.notifications) {
          <div class="notification_menu">
            {{ SharedData.notifications }}
          </div>
          }
        </ng-template>
      </ng-template>
      }

    </kendo-drawer>

    <kendo-drawer-content>
      @if (AuthService.isAuthenticated() && !Managedrawerexpanded && _currentPanel()?.value !== 'crm' ) {
      <div
        (click)="Managedrawerexpanded = true"
        class="ManagedsideBarMenuIconCont"
      >
        <div class="ikbs-frame-61761 ManagedsideBarMenuIcon"></div>
      </div>
      }
      @if(_Menu().length ){
      <div
        class="dump_column_cont"
        [ngClass]="{ showMenu: _menuItemLevelTwo().length > 0 }"
        #menu
      >
        @for (item of _menuItemLevelTwo(); track item) {
        <ul class="hot_menu_items">
          <li
            class="title6 hot_menu_item"
            (click)="menuSelectHot(item)"
            [ngClass]="{ hot_menu_item_selected: item.selected && item.level !=2 }"
          >
            <div
              class="k-level-{{ item.level }}"
              style="display: flex"
            >
              @if (item['children']) {
              <span>{{ item.title }}</span>
              }
              @if (!item['children']) {
              <a [routerLink]="[item.path]">{{
                item.title
                }}</a>
              }
              @if (item.expanded && item['children']) {
              <span
                class="ikbs-arrow_down"
                style="margin-left: auto"
              ></span>
              }
            </div>
            @if (!item.expanded && item['children']) {
            <span
              class="ikbs-arrow_right"
              style="margin-left: auto"
            ></span>
            }
          </li>
        </ul>
        }
      </div>
      }

      <div class="content_container">
        @if (isDeployDIalogOpen) {
        <kendo-dialog (close)="isDeployDIalogOpen = !isDeployDIalogOpen">
          <kendo-dialog-titlebar>
            <div style="text-transform: capitalize;">
              Please confirm Deployment - {{StoreService.getFromStore_Static("current_project")?.alias}} (<b
                style="text-transform: uppercase;;color:#d13a3a"
              >{{env}}</b>) <strong style="background:#d13a3a">{{hugeType =='forceClearCache'?' ~FORCE~':''}}</strong>
            </div>
          </kendo-dialog-titlebar>
          <app-deploy
            [_type]=""
            [hugeType]="hugeType"
            [env]="env"
            (closeDialog)="isDeployDIalogOpen = false"
          ></app-deploy>
        </kendo-dialog>
        }
        <div [@routeAnimations]="prepareRoute(outlet)">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>

      </div>
    </kendo-drawer-content>

    @if (_currentPanel().value !== 'crm') {
    <kendo-drawer
      #Managedrawer
      [mini]="AuthService.isAuthenticated()"
      mode="push"
      [miniWidth]="0"
      [width]="246"
      [(expanded)]="Managedrawerexpanded"
      [autoCollapse]="false"
    >
      <ng-template kendoDrawerHeaderTemplate>
        <div class="managedmenuHeaderTemplate">
          @if (Managedrawerexpanded) {
          <div class="ManagedhotterLogo"></div>
          }
          @if (Managedrawerexpanded) {
          <div
            class="ikbs-frame-61760 ManagedsideBarMenuIconColapse"
            (click)="Managedrawer.toggle()"
          ></div>
          }
        </div>
        @if (Managedrawerexpanded) {
        <div class="nameMan">
          <span class="sub_title4">LOGIN: </span>
          {{ StoreService.getUser()?.email }}
        </div>
        }
        @if (Managedrawerexpanded) {
        <div class="tokenMan">
          <div class="sub_title4">TOKEN:</div>
          <input
            type="text"
            class="token_input"
            [value]="AuthService.token"
            #userinput
            (click)="copyInputMessage()"
          />
        </div>
        }
        @if (Managedrawerexpanded) {
        <div class="deployMan">
          <div class="sub_title4">DEPLOY:</div>
          <button
            (click)="rightToolBarDeployCashProcess('dev', 'deploy',{})"
            class="btn btn-sm action_button"
          >
            Dev
          </button>
          <!-- [disabled]="!deployAccess?.deploy_dev" -->
          <button
            style="margin-left: 10px"
            (click)="rightToolBarDeployCashProcess('stage', 'deploy',{})"
            class="btn btn-sm action_button"
            [disabled]="!deployAccess?.deploy_prod"
          >
            Stage
          </button>
          <button
            style="margin-left: 10px"
            (click)="rightToolBarDeployCashProcess('prod', 'deploy',{})"
            class="btn btn-sm action_button"
            [disabled]="!deployAccess?.deploy_prod"
          >
            Prod
          </button>
          <!--    *ngIf="selectedTab.alias==='basic' && table_sort  && listEnvItems.length === 0" -->
          <!-- [disabled]="!deployAccess?.deploy_prod" -->
        </div>
        }
        @if (Managedrawerexpanded) {
        <div class="cashMan">
          @if (!isFLush) {
          <span class="sub_title4">CLEAR CASH: </span>
          }
          @if (isFLush) {
          <span class="sub_title4">FLUSH CASH: </span>
          }
          <div class="client">
            <button
              (click)="rightToolBarDeployCashProcess('dev', 'clearCash',$event)"
              class="btn btn-sm action_button"
              [ngClass]="{ red_button: isFLush }"
            >
              Dev
            </button>
            <button
              style="margin-left: 10px"
              (click)="rightToolBarDeployCashProcess('stage', 'clearCash',$event)"
              class="btn btn-sm action_button"
              [ngClass]="{ red_button: isFLush }"
            >
              Stage
            </button>
            <button
              style="margin-left: 10px"
              (click)="rightToolBarDeployCashProcess('prod', 'clearCash',$event)"
              class="btn btn-sm action_button"
              [ngClass]="{ red_button: isFLush }"
            >
              Prod
            </button>
            <!-- <label class="sub_title3">CLIENT: </label>
              <kendo-dropdownlist
                [data]="cash_list_client"
                textField="text"
                valueField="value"
                (valueChange)="cashselect($event)"
                [(ngModel)]="cashSelected_client">
              </kendo-dropdownlist> -->
          </div>
          <div class="manager">
            <label class="sub_title3">MANAGER: </label>
            <kendo-dropdownlist
              [data]="cash_list_manager"
              textField="text"
              valueField="value"
              textField="text"
              (valueChange)="cashselect($event)"
              [(ngModel)]="cashSelected_managent"
            >
            </kendo-dropdownlist>
          </div>
        </div>
        }
        @if (Managedrawerexpanded) {
        <app-buffer-grid></app-buffer-grid>
        }
      </ng-template>
      <ng-template
        kendoDrawerItemTemplate
        let-item
        let-index
      > </ng-template>
    </kendo-drawer>
    }
  </kendo-drawer-container>

  <ik-messages></ik-messages>
</div>