import { Formio } from '@formio/angular';
const Components = (Formio as any).Components;

const costumKey = {
    weight: 0,
    type: 'textfield',
    input: true,
    key: 'costumkey',
    label: 'Key to the Schema',
    placeholder: 'Enter Key value',
    tooltip: 'Key that will be used in system',
    validate: {
        required: true
    }
}

const copyTrans = {
    weight: 0,
    type: 'radio',
    label: 'Make this element in multiple languages',
    tooltip: 'Switch on and off the Multilanguage',
    key: 'copyTrans',
    input: true,
    defaultValue: true,
    values: [{
        label: 'One language',
        value: false
    }, {
        label: 'Multilanguage',
        value: true
    }]
}

// Function to override the edit form for all components
export function overrideFormioEditForm() {
    function extendEditForm(originalEditForm: any) {
        return function (...extend: any[]) {
            const editForm = originalEditForm(...extend);

            // Locate the "Display" tab (first panel in edit form)
            const displayTab = editForm.components.find((tab: any) => tab.key === 'tabs');
            const displayTabCom = displayTab.components.find((tab: any) => tab.key === "display");
            if (displayTabCom) {
                displayTabCom.components.unshift(costumKey);
                displayTabCom.components.unshift(copyTrans);
            }

            return editForm;
        };
    }

    // Apply to all components globally
    Object.keys(Components.components).forEach((key) => {
        const component = Components.components[key];
        if (component && component.editForm) {
            component.editForm = extendEditForm(component.editForm);
        }
    });
}