
import { Formio } from '@formio/angular';
const Components = (Formio as any).Components;
const EditGridComponent = Components.components.editgrid;

export function overrideFormioEditGridForm() {
  class CustomEditGrid extends EditGridComponent {
    attach(element: HTMLElement) {
      super.attach(element);

      // Добавляем обработчик событий на кнопку "Copy"
      const buttons = element.querySelectorAll('.ikbs_copy');
      buttons.forEach((button) => {
        button.addEventListener('click', (event) => {
          event.preventDefault();

          const rowElement: any = (event.target as HTMLElement).closest('[ref="editgrid-prizes-row"]');
          const tableElement: any = (event.target as HTMLElement).closest('.editgrid-listgroup');
          const rowIndex: any = Array.from(rowElement?.parentElement?.children || []).indexOf(rowElement) - 1;
          if (rowIndex !== null) {
            // this.copyRow(parseInt(rowIndex, 10));
            console.log(this)
            // this.copyRow(parseInt(rowIndex, 10));
            this['events']['emit']('ikbs_copy', {
              component: this['component'],
              index: parseInt(rowIndex, 10),
              rowData: this['dataValue'][rowIndex]
            });
          }
        });
      });

      return element;
    }

    copyRow(rowIndex: number) {

      if (this['dataValue'] && this['dataValue'][rowIndex]) {
        const copiedRow = { ...this['dataValue'][rowIndex] };
        this['dataValue'].push(copiedRow);
        this['redraw']();
      }
    }
  }

  // 🔥 Заменяем стандартный EditGrid в Formio
  Components.components.editgrid = CustomEditGrid;
}
