import { StoreService } from "@services/store.service";
export const FetchPlugin = {
  /**
   * The priority of the plugin relative to other plugins that determines call
   * order. Higher numbers have higher priority. If not specified it will
   * default to a priority of 0.
   */
  priority: 100,
  preRequest: function (requestArgs: any, ...args: any) {
    if (requestArgs.method !== "upload" && requestArgs.method !== "delete") {
      if (!requestArgs.opts?.header) {
        requestArgs.opts.header = {};
        requestArgs.opts.header.map = {};
      }

      requestArgs.opts.header.map["o-auth-key"] = localStorage.getItem("session-Token");
    }
    // crypt('salt', JSON.stringify(test));
    // console.log(crypt('salt', JSON.stringify(test)));


  },
  request: function (args: any) {
    console.log(args);
    // You can alter the response by changing null to the response you wish to make.
    return Promise.resolve(20);
  },
  /**
   * An initialization function called when registered with Formio.
   *
   * @param Formio - The static class of the Form.io SDK
   */
  init: (Formio: any) => { },

  /**
   * A deregistration function called when deregistering with Formio
   *
   * @param Formio - The static class of the Form.io SDK
   */
  deregister: (Formio: any) => {
    // console.log(Formio)
  },
};
// https://crm-dev.gbl-systems.com/api/en/content/media/upload/needforspin/tournament/en?baseUrl=https%3A%2F%2Fcrm-dev.gbl-systems.com%2Fproject&project=&form=

// const crypt = (salt, text) => {
//   const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
//   const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
//   const applySaltToChar = (code) =>
//     textToChars(salt).reduce((a, b) => a ^ b, code);

//   return text
//     .split('')
//     .map(textToChars)
//     .map(applySaltToChar)
//     .map(byteHex)
//     .join('');
// };
