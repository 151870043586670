import { HTTP_INTERCEPTORS, HttpEvent, HttpInterceptorFn, HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { StoreService } from "./store.service";
import { SocketService } from "./socket.service";
import { ActivatedRoute, Router } from "@angular/router";
import { EMPTY } from "rxjs";
import { InternalMessageService } from "./internalMessage.service";
import { DcService } from "./dc.service";
const TOKEN_KEY: string = "session-Token";
const EXP_TOKEN_KEY: string = "_xpirat";

const TOKEN_HEADER_KEY = "o-auth-key"; // for Spring Boot back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  AuthService: AuthService = inject(AuthService);
  SocketService: SocketService = inject(SocketService);
  router: Router = inject(Router);
  ActivatedRoute: ActivatedRoute = inject(ActivatedRoute);
  InternalMessageService: InternalMessageService = inject(InternalMessageService)
  DcService: DcService = inject(DcService)
  StoreService: StoreService = inject(StoreService)


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes("api/l")) return next.handle(req)
    const startTime = performance.now()
    const startTimeUts = new Date().toISOString()
    let duration = 0
    let code = 0
    let responseSize = ""
    const token = localStorage.getItem(TOKEN_KEY);
    if (localStorage.getItem(EXP_TOKEN_KEY) !== null && !this.AuthService.isAuthenticated()) {
      if (!req.url.indexOf("account/login")) {
        this.AuthService.setToken(null);
        //@ts-ignore
        document.querySelector("body").style["overflowY"] = "hidden";
        if (this.SocketService.isConnected) {
          this.SocketService.disconnect();
        }
        this.router.navigate(["login"]);
        this.InternalMessageService.errorMessage("Token Expired");
        return EMPTY;
      }
    }
    if (token && req.url.indexOf("account/login") == -1) {
      req = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) });
    }

    req = req.clone({ headers: req.headers.set("Accept", "application/json").set('ngsw-bypass', 'true') });
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const endTime = performance.now();
          duration = endTime - startTime; // Рассчитываем длительность

          code = event.status;
          responseSize = event.headers.get('content-length') || "";
          if (!responseSize && event.body) {
            responseSize = new Blob([JSON.stringify(event.body)]).size.toString(); // Estimate size
          }
        }
      }),
      catchError((err) => {
        if (err && err.type == "API" && err.status > 400 && err.status < 500) {
        }

        if (err && err.status === 401 && err.error && typeof err.error === "string" && err.error.indexOf("Invalid token") !== -1) {
          this.AuthService.setToken(null);
          // document.querySelector('body').style['overflowY'] = 'hidden'
          if (this.SocketService.isConnected) {
            this.SocketService.disconnect();
          }
          this.router.navigate(["/login"]);
        }

        if (
          err &&
          err.error &&
          typeof err.error === "string" &&
          err.error.indexOf("Access denied") !== -1 &&
          (err.url.indexOf("project/interface") || err.url.indexOf("account/role") || err.url.indexOf("en/menu"))
        ) {
          this.AuthService.setToken(null);
          // document.querySelector('body').style['overflowY'] = 'hidden'
          if (this.SocketService.isConnected) {
            this.SocketService.disconnect();
          }
          this.router.navigate(["/login"]);
        }
        return throwError(err);
      }),
      finalize(() => {
        const href = window.location.href;
        const endTimeRender = performance.now(); // Засекаем время при получении ответа
        const durationRender = endTimeRender - startTime; // Рассчитываем длительность
        this.DcService.datatosend.push([href, code, responseSize, startTimeUts, duration.toFixed(5), durationRender.toFixed(5),])
      })
    );
  }
}

export const authInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
