import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, inject, Input, OnChanges, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { SharedData } from "@services/sharedData.service";
import { StoreService } from "@services/store.service";
import { distinctUntilChanged, map } from "rxjs/operators";
import { AuthService } from "@services/auth.service";
import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { InternalMessageService } from "@services/internalMessage.service";
import { timer } from "rxjs";

@Component({
  selector: "ik-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass, JsonPipe, AsyncPipe],
  standalone: true,
})
export class MessagesComponent implements OnInit, OnChanges, AfterViewInit {
  SharedData: SharedData = inject(SharedData);
  StoreService: StoreService = inject(StoreService);
  AuthService: AuthService = inject(AuthService);
  cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  InternalMessageService: InternalMessageService = inject(InternalMessageService)

  public socketMessage$: Subject<any> = new Subject<any>();
  public socketAlertMessage$: Subject<any> = new Subject<any>();


  public messageCollector: any = {
    "top-left": [],
    "top-center": [],
    "top-right": [],
    "bottom-left": [],
    "bottom-center": [],
    "bottom-right": [],
  };
  public current_panelName: string = "";
  public messageModalCollector: any = [];
  public composit: any = void 0;

  @Input() data: any;

  ngOnInit(): void {
    this.InternalMessageService.Message$.subscribe((response) => {
      if (this.current_panelName == "crm") {
        if (this.StoreService.getFromSCL('route')?.split('/')?.[2] == "payments") return
        for (var i = 0; i < response.length; i++) {
          if (response[i]?.data == "ikbs") continue;
          timer(5000).subscribe((res: any) => {
            this.InternalMessageService.closeMessage(res);
            this.cdRef.detectChanges();
          });
        }
      }
    });
    this.StoreService.storeObserv$
      .pipe(
        map((elem: any) => {
          if (elem?.panel_name) {
            return elem.panel_name;
          }
        }),
        distinctUntilChanged()
      )
      .subscribe((res: any) => {
        if (res) {
          this.messageModalCollector = [];
          this.socketAlertMessage$.next(this.messageModalCollector);
          for (let [key, value] of Object.entries(this.messageCollector)) {
            this.messageCollector[key] = [];
          }
          this.socketMessage$.next(this.messageCollector);
          this.current_panelName = res;
        }
      });

    this.SharedData.socketMessageCollector.subscribe((res) => {
      console.log(res)
      this.Messages(res);
      if (this.current_panelName == "crm") {
        if (this.messageModalCollector.length > 0) {
          this.closeAlertMessage(0);
        } else {
          timer(4000).subscribe((res: any) => {
            this.closeAlertMessage(0);
          });
        }
      }
    });
  }

  ngAfterViewInit(): void { }

  ngOnChanges(changes: any): void { }

  Messages(income: any) {
    if (income.hideTime && income.hideTime > 0) {
      this.timeremover(income);
    }
    if (income.type == "alert") {
      if (income.messages.length > 0) {
        income.messages = this.messageBR(income.messages);
      }
      if (income.module == this.current_panelName || income.module == "all" || !income.module) {
        this.messageCollector[income.position].unshift(income);
        this.socketMessage$.next(this.messageCollector);
      }
    }
    if (income.type == "alert_modal") {
      Promise.resolve().then(() => {
        if (income.module == this.current_panelName || income.module == "all" || !income.module) {
          this.messageModalCollector.push(income);
          this.socketAlertMessage$.next(this.messageModalCollector);
        }
      });

    }
  }

  timeremover(income: any) {
    let message = income;
    if (income.type == "alert") {
      timer(message.hideTime).subscribe((res: any) => {
        this.messageCollector[message.position] = this.messageCollector[message.position].filter((el: any) => el !== income);
        this.socketMessage$.next(this.messageCollector);
        this.cdRef.detectChanges();
      });
    }
    if (income.type == "alert_modal") {
      timer(message.hideTime).subscribe((res: any) => {
        this.messageModalCollector = this.messageModalCollector.filter((el: any) => el !== income);
        this.socketAlertMessage$.next(this.messageModalCollector);
        this.cdRef.detectChanges();
      });
    }
  }

  messageBR(messageArray: any) {
    return messageArray.join("<br>");
  }

  closeMessage(itemToclose: any, position: any) {
    this.messageCollector[position].splice(this.messageCollector[position].length - 1 - itemToclose, 1);
    this.cdRef.detectChanges();
  }

  closeAlertMessage(itemToclose: any) {
    this.messageModalCollector.splice(this.messageModalCollector.length - 1 - itemToclose, 1);
    this.cdRef.detectChanges();
  }

  clearMessageCollector(name: any) {
    this.messageCollector[name] = [];
  }

  closeComposit() {
    this.composit = void 0;
  }

  showDetails(item: any) {
    this.composit = item.body;
  }
}
